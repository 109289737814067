import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { categories } from "../data/data"
import { Link } from "gatsby"

const IndexPage = ({ pageContext }) => {
  const { data: game } = pageContext

  const getCategoryBadge = categoryId => {
    const category = categories[categoryId]
    return category ? (
      <span
        style={{
          backgroundColor: category.color,
          borderRadius: "6px",
          padding: "3px 8px",
          color: "white",
          fontSize: "12px",
        }}
      >
        {category.title}
      </span>
    ) : null
  }

  return (
    <Layout>
      <Seo title={game?.title} />

      <Box
        sx={{
          position: "relative",
          borderRadius: "10px",
          padding: { xs: "14px", md: "16px" },
          transition: "all .1s ease-in-out",
          alignItems: "center",
          backgroundColor: "#faefe6",
          border: "1px solid rgb(98, 41, 30, 0.1)",
          "&:hover": {
            border: "1px solid black",
          },
          "&:hover .iconOpacity": {
            opacity: 1,
          },
        }}
      >
        {game.image_url ? (
          <img
            src={game.image_url}
            style={{
              width: 80,
              height: 80,
              marginRight: "14px",
              borderRadius: "8px",
            }}
            alt={game.slug}
          />
        ) : (
          <Box
            style={{
              width: "100px",
              marginRight: "14px",
              height: "100px",
            }}
          >
            <div
              style={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              }}
            />
          </Box>
        )}
        <Box style={{ marginTop: -25 }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "21px",
              display: "flex",
              alignItems: "center",
              marginTop: -10,
            }}
          >
            {game.title}{" "}
            <a
              href={`${game.url}?utm_source=langlearngames.com&utm_medium=referral`}
              target="_blank"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                  ml: 1,
                }}
              >
                <OpenInNewIcon style={{ fontSize: 22 }} />
              </Box>
            </a>
          </div>

          <div style={{ fontSize: "17px", marginTop: "10px" }}>
            {game.description}
          </div>
          <Box sx={{ mt: 1 }}>{getCategoryBadge(game.categories[0])}</Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default IndexPage
